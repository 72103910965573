import React from 'react';
import Animate from '../Components/Animate';

const TheoryofCrash = () => {
  return (
    <Animate>
      <div className="w-full h-screen flex items-center justify-center">
        {/* Your iframe displaying the external page */}
        <iframe
          src="https://prod.fastplaynetwork.com/games/ph_theoryofcrash_97/index.html?locale=en-gb&currency=EUR"
          title="Theory of Crash"
          width="100%"
          height="100%"
          frameBorder="0"
          allowFullScreen
          className="rounded-lg shadow-lg"
          style={{ border: 'none' }}
        ></iframe>
      </div>
    </Animate>
  );
};

export default TheoryofCrash;

//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCTSQh6xvo2dXn0w-i2v1iXV23YwxUmVwo",
  authDomain: "hamster-tap-5b049.firebaseapp.com",
  projectId: "hamster-tap-5b049",
  storageBucket: "hamster-tap-5b049.appspot.com",
  messagingSenderId: "786852117820",
  appId:  "1:786852117820:web:32f13f25c45d4cd000b239",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
